import React, { Component } from 'react'
import '../CssFiles/Header.css'
import marq from '../Assets/marq.jpg'

class Header extends Component {
   
    render() {
        return (
            <div className="HeaderContainer">
                <div>
                    <div id="AppBar-image-Container">
                        <img src={marq} id="UserImage" alt=""/>
                    </div>
                </div>
            </div>
        )
    }
}
export default Header
