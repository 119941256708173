import React, { Component } from 'react'
import Header from './Header';
class MyProfile extends Component {
    render() {
        return (
            <div>
            <Header />
            <div>
                this is MyProfile
            </div>
           
            </div>
        )
    }
}
export default MyProfile;