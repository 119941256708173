import React, { Component } from 'react'
import Header from './Header';
class SQLTutorial extends Component {
    render() {
        return (
            <div>
            <Header />
            <div>
                this is SQl
            </div>
           
            </div>
        )
    }
}
export default SQLTutorial;