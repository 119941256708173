import React, { Component } from 'react'
class CourseCard extends Component {
    render() {
        return (
            <div>
            <div>
                This is CourseCard
            </div>
            </div>
        )
    }
}
export default CourseCard;