import React, { Component } from 'react'
import '../CssFiles/Login.css';
import logo from '../Assets/logo.png';
import SignUp from '../Components/SignUp';




class Login extends Component {
    
    signup = (event) => {
        this.props.history.push("/signup")
    }
    navDrawer = () => {
        this.props.history.push("/HomePage")
    }

    render() {
        return (
            <div className="container">
                <div className="form-box">
                    <div className="header-form">
                        <div className="Login-logoImage">
                            <img src={logo} className="imageDesign" />
                        </div>
                        <div className="UserInput-section">
                        <div>
                            <input type="text" name="Username" placeholder="Email" className="username-Input"/>
                        </div>
                        <div>
                            <input type="password" name="password" placeholder="Password" className="password-Input"/>
                        </div>
                        </div>
                        <button className="sign-button"
                        onClick={event => this.navDrawer(event)}
                        >Log in</button>
                        <div className="create-Acount">
                             <text>Don't have an account?</text> <div className="signUp-text"><button onClick={event => this.signup(event)}>Sign up</button></div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}



export default Login;