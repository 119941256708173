
import React from 'react'
import {  BrowserRouter, Route} from 'react-router-dom'
import Login from './Login'
import signup from './SignUp'
import Home from './Home'
import HomePage from './HomePage'
import JavaScriptTutorial from './JavaScriptTutorial'
import ReactTutorial from './ReactTutorial'
import ReactNativeTutorial from './ReactNativeTutorial'
import NodeTutorial from './NodeTutorial'
import SQLTutorial from './SQLTutorial'
import MyProfile from './MyProfile'
import CourseCard from './CourseCard'






const Routes = () => (

    <BrowserRouter>
        <div>
            <switch>
            <Route path="/" component={Login} exact={true}/>
            <Route path = "/signup" component= {signup} exact = {true}/>
            <Route path = "/HomePage" component = {HomePage}/>
            <Route path = "/HomePage/Home" component = {Home}/>
            <Route path = "/HomePage/CourseCard" component = {CourseCard}/>
            <Route path = "/HomePage/JavaScriptTutorial" component = {JavaScriptTutorial}/>
            <Route path = "/HomePage/ReactTutorial" component = {ReactTutorial}/>
            <Route path = "/HomePage/ReactNativeTutorial" component = {ReactNativeTutorial}/>
            <Route path = "/HomePage/NodeTutorial" component = {NodeTutorial}/>
            <Route path = "/HomePage/SQLTutorial" component = {SQLTutorial}/>
            <Route path = "/HomePage/MyProfile" component = {MyProfile}/>
            </switch>
        </div>
    </BrowserRouter>
)

export default Routes
