import React, { Component } from 'react'
import CourseCard from './CourseCard'
import Header from './Header'
class Home extends Component {
    render() {
        return (
            <div>
            <Header />
            <div>
                
            {/* this is the home container */}
            {/* <CourseCard /> */}

            </div>
            </div>
        )
    }
}
export default Home;