import React, { Component } from 'react'
import Header from './Header'
class JavaScriptTutorial extends Component {
    render() {
        return (
            <div>
               <Header />
            <div>
                this is JavaScript
            </div>
           
            </div>
        )
    }
}
export default JavaScriptTutorial;