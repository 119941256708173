import React, { Component } from 'react'
import '../CssFiles/SignUp.css';
import logo from '../Assets/logo.png';
class SignUp extends Component {

    login = (event) => {
        this.props.history.push("/")
    }

    render() {
        return (
            <div className="SignUp-main-container">
                <div className="Signup-area-container">
                    <img src={logo} className="imageDesign2" />
                    <div className="UserInput-section">
                        <div>
                            <input type="text" name="Fullname" placeholder="Full Name" className="fullname-Input" />
                        </div>
                        <div>
                            <input type="text" name="Username" placeholder="Email" className="username-Input" />
                        </div>
                        <div>
                            <input type="password" name="password" placeholder="Password" className="password-Input" />
                        </div>
                        <button className="sign-button">Sign up for free</button>
                        <div className="create-Acount">
                            <text>Already have an account?</text> <div className="signUp-text"><button onClick={event => this.login(event)} className="SigInLink">Log In</button></div>
                        </div>

                    </div>
                </div>
            </div>

        )
    }

}



export default SignUp;