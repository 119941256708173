import React, { Component } from 'react'
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Divider, MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import auth from './Auth'
import logo1 from '../Assets/logo1.png'
import '../CssFiles/HomePage.css'


class HomePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
     
    }
  }

  style = () => {
    const useStyle = createMuiTheme(({
      overrides: {
        MuiDrawer: {
          paper: {
            width: "300px",
            backgroundColor: "#FEFEFE",
            color: "black",
          }
        },
        MuiTypography: {
          body1: {
            fontSize: "16px",
          }
        },
        MuiListItem: {
          button: {
            backgroundColor: "",
            "&:hover": {
              backgroundColor: "#DADAD6",
              transition: "1.5s"
            }
          }
        },
        MuiListItemIcon: {
          root: {
            marginLeft: "50px"
          }
        },
        MuiListItemText: {
          root: {
            marginLeft: "-10px"
          }
        }

      }
    }))
    return useStyle
  }

  Home = (event) => {
    this.props.history.push("/HomePage/Home")
  }

  LiveChat = (event) => {
    this.props.history.push("/HomePage/LiveChat")
  }
  JavaScriptTutorial = (event) => {
    this.props.history.push("/HomePage/JavaScriptTutorial")
  }

  ReactTutorial = (event) => {
    this.props.history.push("/HomePage/ReactTutorial")
  }

  ReactNativeTutorial = (event) => {
    this.props.history.push("/HomePage/ReactNativeTutorial")
  }

  NodeTutorial = (event) => {
    this.props.history.push("/HomePage/NodeTutorial")
  }

  SQLTutorial = (event) => {
    this.props.history.push("/HomePage/SQLTutorial")
  }

  MyProfile = (event) => {
    this.props.history.push("/HomePage/MyProfile")
  }


  

  logoutHandle() {
    auth.logout(() => {
      this.props.history.push("/")
    })
  }

  render() {
    return (
      <div>
        <MuiThemeProvider theme={this.style()}>

          <Drawer
            className={""}
            variant="permanent"
            anchor="left"
          >
            <Divider />

            <div className="comp-logo">
              <div id="coin-div">
                <img src={logo1} style={{ height: "137px" }} />
              </div>
              <div id="company-name">
                Weool.com
              </div>
            </div>
            <Divider />
            <List>
              <ListItem id={(this.state.isHome) ? "listItem" : ""}
                onClick={event => this.Home(event)}
                button>
                <ListItemIcon>
                  {/* <img src={require('../Assets/home.png')} id="homeImg" /> */}
                </ListItemIcon>
                <ListItemText primary={"Home"} />
              </ListItem>

              <ListItem id={(this.state.JavaScript) ? "listItem" : ""}
                button onClick={event => this.JavaScriptTutorial(event)} >
                <ListItemIcon>
                  {/* <img src={require('../Assets/search.png')} id="homeImg" /> */}
                </ListItemIcon>
                <ListItemText primary={"JavaScript"} />
              </ListItem>

              <ListItem id={(this.state.ReactTutorial) ? "listItem" : ""}
                button onClick={event => this.ReactTutorial(event)}>
                <ListItemIcon>
                  {/* 
                                    <img src={require('../Assets/speech-bubble.png')} id="homeImg" /> */}
                </ListItemIcon>
                <ListItemText primary={"React"} />
              </ListItem>

              <ListItem id={(this.state.ReactNativeTutorial) ? "listItem" : ""}
                onClick={event => this.ReactNativeTutorial(event)}
                button>
                <ListItemIcon>
                  {/* <img src={require('../Assets/group.png')} id="homeImg" /> */}
                </ListItemIcon>
                <ListItemText primary={"React Native"} />
              </ListItem>

              <ListItem id={(this.state.isEarnings) ? "listItem" : ""}
                onClick={event => this.NodeTutorial(event)}
                button>
                <ListItemIcon>
                  {/* <img src={require('../Assets/dollar.png')} id="homeImg" /> */}
                </ListItemIcon>
                <ListItemText primary={"Node.js"} />
              </ListItem>

              <ListItem id={(this.state.SQLTutorial) ? "listItem" : ""}
                onClick={event => this.SQLTutorial(event)}
                button>
                <ListItemIcon>
                  {/* <img src={require('../Assets/chat.png')} id="homeImg" /> */}
                </ListItemIcon>
                <ListItemText primary={"SQL"} />
              </ListItem>

              <ListItem id={(this.state.MyProfile) ? "listItem" : ""}
                onClick={event => this.MyProfile(event)}
                button>
                <ListItemIcon>
                  {/* <img src={require('../Assets/setting.png')} id="homeImg" /> */}
                </ListItemIcon>
                <ListItemText primary={"My Profile"} />
              </ListItem>
              <ListItem
                onClick={(event) => this.logoutHandle(event)}
                button
              >
                <ListItemIcon>
                  {/* <img src={require('../Assets/history.png')} id="homeImg" /> */}
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItem>
            </List>

            <Divider />

          </Drawer>
        </MuiThemeProvider>
      </div>
    )
  }
}

export default HomePage